import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import styles from './index.module.css';

const IndexPage = ({ data }) => (
  <Layout bounded={false} topPadded={false}>
    <div
      style={{
        backgroundColor: '#5c5135',
        padding: '64px 48px 48px',
      }}
    >
      <div
        style={{
          flex: '1',
          maxWidth: '300px',
          margin: '0 auto 48px'
        }}
      >
        <Img fluid={data.logoImage.childImageSharp.fluid} />
      </div>
      <div className={styles.start}>
        <Link to="/opening">Start Reading &gt;</Link>
      </div>
    </div>
    <ul className={styles.contents}>
      <li><Link to="/opening">Opening remarks</Link></li>
      <li><Link to="/cabernet">Cabernet in Australia and the creation of Quintet</Link></li>
      <li><Link to="/sustainability">Environmental sustainability in wine production</Link></li>
      <li><Link to="/global">Our global footprint</Link></li>
      <li><Link to="/chateau-margaux">Chateau Margaux</Link></li>
      <li><Link to="/team">Team update</Link></li>
      <li><Link to="/vineyard">What&apos;s happening in our vineyard</Link></li>
      <li><Link to="/jwdm">JWDM</Link></li>
      <li><Link to="/vintage">2019 vintage report</Link></li>
      <li><Link to="/events">Mount Mary events</Link></li>
      <li><Link to="/visiting">Visiting Mount Mary over release weekends</Link></li>
      <li><Link to="/marli-russell">Marli Russell</Link></li>
      <li><Link to="/release">This year&apos;s release</Link></li>
      <li><Link to="/members-table">Members&apos; Table</Link></li>
      <li><Link to="/closing">Closing remarks</Link></li>
    </ul>
    <div className={styles.buttons}>
      <a href="https://www.mountmary.com.au">Members&apos; Table login to place order</a>
      <a href="https://www.mountmary.com.au/ml_2019orderform">Order form</a>
    </div>
  </Layout>
);

IndexPage.propTypes = {
  data: PropTypes.shape({
    logoImage: PropTypes.object,
  }),
};

export const query = graphql`
  query {
    logoImage: file(relativePath: { eq: "MM-Bronze.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default IndexPage;
